
function resize_main(){

	var height_page   = jQuery(window).height();
	var height_header = jQuery('header.page').innerHeight();
	var height_footer = jQuery('footer.page').innerHeight();
	
	var height_main   = height_page - height_header;
	var height_slider = height_main - 100 - 50;
	var height_slider_min = 600;

}

jQuery(document).ready(function($){

	resize_main();

	var $navbarBurgers = jQuery('.navbar-burger');
  	if ($navbarBurgers.length > 0) {
	    $navbarBurgers.on('click', function () {
			var target = jQuery(this).attr('data-target');
			jQuery(this).toggleClass('is-active');
			jQuery('#'+ target).toggleClass('is-active');

		});
	}

	jQuery(window).resize(function(){
		resize_main();
	});
  
});
